
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


/* Video related*/
@tailwind utilities;

:root {
  --font-inter: Outfit;
}

html,
body {
  height: 100%;
  font-family: Outfit, sans-serif;
}

::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(1, 45, 106);
  border-radius: 5px;
  opacity: 0.8;
}

.color-picker-container {
  display: flex;
  position: absolute;
  width: 250px;
  /* Adjust width as needed */
  z-index: 10000;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  /* Set your desired background color */
  padding: 20px;
  border-radius: 8px;
}

.swatches-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 20px;
  width: 100%;
  /* Ensure the container takes full width */
}

/* Additional CSS to ensure swatches are centered in their container */
.circle-picker {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.color-picker-container .chrome-picker {
  box-shadow: none !important;
  /* Removes the shadow effect */
  background: transparent !important;
  /* Makes the background transparent */
  border: none !important;
  /* Removes the border */
}



/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* .timeline-editor{
    background-color: white !important;
  } */
.timeline-editor {
  width: 100% !important;
  background-color: white !important;
}

.timeline-editor-time-unit {
  border-right: 1px solid gray !important;
}

.timeline-editor-time-unit-scale {
  color: gray !important;
}

.timeline-editor-action {
  background-color: #fda81d !important;
  border-radius: 4px;
  border: 2px solid #ff7e00;
}

.timeline-editor-action .timeline-editor-action-left-stretch::after {
  border-left: 7px solid rgb(143 67 17 / 37%);
}

.timeline-editor-edit-row {
  background-image: linear-gradient(white, white), linear-gradient(90deg, rgba(255, 255, 255, 0.08) 1px, transparent 0) !important;
}

.timeline-editor-time-area-interact {
  border-bottom: 1px solid #cdcdcd;
}

.custom-color-picker {
  width: 200px;
  height: 150px;
}

.text-element-heading-title {
  font-family: "Roboto";
  font-size: 1.375rem;
  text-align: center;
  color: rgb(0, 0, 0);
  font-weight: bold;
}

.text-element-simple {
  font-family: "Montserrat";
  font-size: 1rem;
  font-weight: 600;
  text-align: center;
  color: rgb(24, 25, 27);
}

.text-element-simple {
  font-family: "Montserrat";
  font-size: 1rem;
  font-weight: 600;
  text-align: center;
  color: rgb(24, 25, 27);
}

.text-element-handwriting {
  font-family: "Damion";
  font-size: 1.5rem;
  text-align: center;
  color: rgb(0, 0, 0);
  font-weight: 400;
}

.text-element-typewriter {
  font-family: "Source Code Pro";
  font-size: 1.25rem;
  text-align: center;
  color: rgb(0, 0, 0);
  font-weight: 700;
}

.text-element-elegant {
  font-family: 'Playfair Display', serif;
  font-weight: 400;
  text-align: center;
  color: rgb(0, 0, 0);
  font-weight: 400;
}

.text-element-modern {
  font-family: 'Lato', sans-serif;
  font-weight: 700;
  text-align: center;
  color: rgb(0, 0, 0);
  font-weight: 400;
}

.text-element-classic {
  font-family: 'Merriweather', serif;
  font-weight: 900;
  text-align: center;
  color: rgb(0, 0, 0);
  font-weight: 400;
}

.text-element-sans-serif {
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  text-align: center;
  color: rgb(0, 0, 0);
  font-weight: 400;
}

.text-element-casual {
  font-family: 'Raleway', sans-serif;
  font-weight: 500;
  text-align: center;
  color: rgb(0, 0, 0);
  font-weight: 400;
}

.text-element-script {
  font-family: 'Pacifico', cursive;
  font-weight: 400;
  text-align: center;
  color: rgb(0, 0, 0);
  font-weight: 400;
}

.text-element-clean {
  font-family: 'Nunito', sans-serif;
  font-weight: 600;
  text-align: center;
    color: rgb(0, 0, 0);
    font-weight: 400;
}

.triangle {
  line-height: 1;
  width: 0.6rem;
  height: 0.6rem;
  border-top-right-radius: 20%;
  text-align: left;
  display: inline-block;

  transform: rotate(-120deg) skewX(-30deg) scale(1, 0.866);
  transform-origin: 30% 45%;
}

.triangle:before,
.triangle:after {
  content: '';
  position: absolute;
  background-color: inherit;
  width: 100%;
  height: 100%;
  border-top-right-radius: 20%;
}

.triangle:before {
  transform: rotate(-135deg) skewX(-45deg) scale(1.414, 0.707) translate(0, -50%);
}

.triangle:after {
  transform: rotate(135deg) skewY(-45deg) scale(0.707, 1.414) translate(50%);
}


.container-animation {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.animation-container {
  width: calc(33.33% - 10px);
  /* Adjust as needed */
  margin-bottom: 20px;
  /* Adjust spacing between rows */
}

.frbGcB {
  font-family: Outfit, sans-serif;
  font-size: 0.8125rem;
  line-height: 1rem;
  letter-spacing: 0px;
  font-weight: 400;
  color: rgb(92, 94, 101);
}

.hdYsif {
  position: relative;
  order: 0;
}

.ihTHAJ {
  color: rgb(165, 167, 173);
}

.gCOnki {
  display: none;
  position: absolute;
  width: 13px;
  height: 13px;
  left: calc(50% - 6.5px);
  bottom: -23px;
  background: rgb(255, 255, 255);
  border-width: 1px 0px 0px 1px;
  border-style: solid;
  border-color: rgb(225, 225, 227);
  border-radius: 3px 0px 0px;
  transform: rotate(45deg);
  z-index: 1;
}



.hjVfOB {
  position: relative;
  width: 100px;
  height: 5.3125rem;
  min-height: auto;
  border-style: solid;
  border-width: 2px;
  border-radius: 0.625rem;
  overflow: visible;
  border-color: rgb(238, 238, 240);
  background-color: rgb(255, 255, 255);
  transition: background-color 0.25s ease 0s, border-color 0.25s ease 0s;
}

.canvas-holder {
  position: fixed;
  left: 64px;
  top: 92px;
  width: calc(100% - 64px);
  height: calc(100% - 95px);
  align-content: center;
  overflow: hidden;
  box-sizing: border-box;
}

.canvas-container {
  display: inline-block;
  background-color: #FEFEFE;
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAHUlEQVQ4jWNgYGAQIYAJglEDhoUBg9+FowbQ2gAARjwKARjtnN8AAAAASUVORK5CYII=');
  background-size: 30px 30px;
  background-position: 0 0, 15px 15px;
  box-sizing: content-box;
}

.canvas-info {
  width: 100%;
  font-size: 13px;
  color: #999;
  position: relative;
  text-align: left;
  line-height: 18px;
  margin-top: -42px;
  text-align: center;
}